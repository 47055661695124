import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import NotificationAlert from "react-notification-alert";
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import * as actions from '../../../../redux/Deal/actions';
import * as constants from 'constants/index.js';
import InputCustom from 'views/pages/components/InputCustom';
import { InputArraySelect } from 'views/pages/components/InputSelect';
import axios from 'axios';
import TableCustom from 'views/pages/components/TableCustom';
import { saveCustomInvoicePayment } from 'api/payments.api';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  CardHeader,
  FormGroup
} from "reactstrap";
import { AddNote } from './modals/AddNote';
import { UpdateThemeBrandingModal } from './modals/UpdateThemeBrandingModal';
import { ClipLoader } from 'react-spinners';
import { ButtonContainer, FilterButtons } from "@pages/reusable-components/styled-components";
import { parseToWord } from "constants/index.js";

export default function PaymentDetails() {
  const { id, scheduleId } = useParams();
  const history = useHistory();

  const { salesAccounts, ...dealState } = useSelector((state) => state.dealState, shallowEqual);
  const modules = useSelector((state) => state.getState).modules.modules || [];
  const dispatch = useDispatch();

  const [deal, setDeal] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentStatusFailed, setPaymentStatusFailed] = useState('');
  const [paymentStatusNew, setPaymentStatusNew] = useState('');
  const [processTypeDirectDebit, setProcessTypeDirectDebit] = useState('');
  const [paymentOnlineProcessLink, setPaymentOnlineProcessLink] = useState(false);

  const [addInvoicePaymentModal, setAddInvoicePaymentModal] = useState(false);
  const [customPaymentElement, setCustomPaymentElement] = useState({})
  const [customPaymentElementError, setCustomPaymentElementError] = useState(null)
  const [paymentEdit, setPaymentEdit] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(true);
  const [actualInvoice, setActualInvoice] = useState(false);
  const [brandingThemes, setBrandingThemes] = useState([]);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [updateThemeBrandingModal, setUpdateThemeBrandingModal] = useState(false);
  const openPaymentLink = (link) => {
    var win = window.open(link, '_blank');
    win.focus();
  }

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
  `;

  useEffect(() => {
    getSalesAccounts();
  }, [id])

  const getSalesAccounts = () => {
    dispatch({
      type: actions.GET_SALES_ACCOUNTS_REQUEST
    });
  };

  const showInvoice = (row) => {
    let token = 1;
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/invoice/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        console.log(response)
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          let invoice = data.data.invoice;
          window.open(invoice, "_blank");
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const addCustomInvoicePayment = () => {
    setCustomPaymentElementError(null);
    saveCustomInvoicePayment(deal.id, selectedPayment.id, customPaymentElement)
      .then(response => {
        if (response.data.payment) {
          let data = response.data;
          console.log('response data :: ', data);
          setAddInvoicePaymentModal(false);
          notify('success', 'Success', data.message);
          setCustomPaymentElement({});
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        // notify('warning', 'Warning', error?.response?.data?.message);
        setCustomPaymentElementError(error?.response?.data?.message ?? 'Error occurred during processing of the payment transaction');
      })
  }

  const goDeal = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/edit',
    })
  }

  const goToEditInvoice = () => {
    history.push({
      pathname: `/admin/deals/${id}/payment/${scheduleId}/edit`,
    })
  }

  const goTopayments = () => {
    history.push({
      pathname: `/admin/deals/${id}/payment`,
    })
  }

  const getPaymentRequest = (scheduleId) => {
    dispatch({
      type: actions.GET_PAYMENT_DETAILS_REQUEST,
      id: id,
      scheduleId: scheduleId,
    });
  };

  const sendEmail = () => {
    setLoading(true);
    dispatch({
      type: actions.SEND_PAYMENT_INVOICE_EMAIL_REQUEST,
      payload: {
        scheduleId: selectedPayment.id,
        dealId: deal.id,
      }
    });
  };

  useEffect(() => {
    getPaymentRequest(scheduleId)
  }, [scheduleId, id])

  useEffect(() => {
    if (dealState.paymentDetails) {
      let data = dealState.paymentDetails;
      console.log('response data :: ', data);
      setDeal(data.deal);
      setSelectedPayment(data.item);
      setPaymentStatusFailed(data.paymentStatusFailed);
      setPaymentStatusNew(data.paymentStatusNew);
      setProcessTypeDirectDebit(data.processTypeDirectDebit);
      setPaymentOnlineProcessLink(data.addOnlinePayment);
      setActualInvoice(data.actualInvoice);
      setBrandingThemes(data.brandingThemes);
      setLoading(false);
      setPaymentEdit({
        ...paymentEdit,
        scheduled_at: data.item && data.item.reference ? constants.formatDate(data.item.reference.charge_date) : constants.formatDate(data.item.scheduled_at),
        amount: data.item && data.item.reference ? data.item.reference.amount : data.item.amount,
        process_type: data.item && data.item.reference ? data.item.reference.process_type : '',
        payment_reference_id: data.item ? data.item.id : '',
        selected_provider: data.item && data.item.reference ? data.item.reference.selected_provider : '',
        collect_now: 0,
      })
    }
  }, [dealState.paymentDetails])

  useEffect(() => {
    if (dealState.paymentInvoiceEmail === true) {
      notify('success', 'Success', "Email succesfully sent!");
    } else if (dealState.paymentInvoiceEmail === false) {
      notify('warning', 'Warning', "Error sending the email!");
    }

    setLoading(false);
  }, [dealState.paymentInvoiceEmail]);


  const notificationAlert = useRef(null);
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const savePayment = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/save-reference',

      data: paymentEdit,
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {

        } else if (error.response && error.response.status === 406) {
        }
      })
  }

  return (
    <>
      <CardsHeader name="Deal Management" parentName="Deals Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">{`Payment Details`}, {parseToWord(selectedPayment.payment_descriptor)}</h1>
                  </div>
                  <Row>
                    <Col>
                      <FilterButtons background={'#fff'} onClick={() => goDeal()}>Deal</FilterButtons>
                    </Col>
                    <Col>
                      <FilterButtons background={'#fff'} onClick={() => goTopayments()}>Payments</FilterButtons>
                    </Col>
                  </Row>
                </div>
              </CardHeader>

              {actualInvoice ?
                <CardBody>
                  <Row className={'mb-3'}>
                    <Col lg={8}>
                      <table className={'w-100'}>
                        <thead>
                          <tr>
                            <th tabIndex="0">
                              Sub Total
                            </th>
                            <th tabIndex="0">
                              Total Taxes
                            </th>
                            <th tabIndex="0">
                              Total
                            </th>
                            <th tabIndex="0">
                              Payment Due
                            </th>
                            <th tabIndex="0">
                              Amount Paid
                            </th>
                            <th tabIndex="0">
                              Amount Due
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {actualInvoice.sub_total}
                            </td>
                            <td>
                              {actualInvoice.total_tax}
                            </td>
                            <td>
                              {actualInvoice.total}
                            </td>
                            <td>
                              {constants.formatDate(actualInvoice.due_date)}

                            </td>
                            <td>
                              {actualInvoice.amount_paid}
                            </td>
                            <td>
                              {actualInvoice.amount_due}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h2>{actualInvoice.invoice_number} - <span className="text-danger">{actualInvoice.status}</span></h2>
                    </Col>
                    <Col md={2}>
                      <FilterButtons
                        background={'#fff'}
                        onClick={() => goToEditInvoice()}
                      >
                        {`Edit invoice`}
                      </FilterButtons>
                    </Col>
                    <Col md={3}>
                      <FilterButtons
                        background={'#fff'}
                        onClick={() => setAddInvoicePaymentModal(true)}
                      >
                        {`Add new payment`}
                      </FilterButtons>
                    </Col>
                    <Col md={3}>
                      <FilterButtons
                        background={'#fff'}
                        onClick={() => showInvoice(selectedPayment)}
                      >
                        {`Download Invoice`}
                      </FilterButtons>
                    </Col>
                    <Col md={2}>
                      <FilterButtons
                        background={'#fff'}
                        onClick={() => setAddNoteModal(true)}
                      >
                        {`Add note`}
                      </FilterButtons>
                    </Col>
                    <Col md={2}>
                      <FilterButtons
                        background={'#fff'}
                        onClick={() => setUpdateThemeBrandingModal(true)}
                      >
                        {`Update theme branding`}
                      </FilterButtons>
                    </Col>
                    {['PAID', 'SUBMITTED', 'AUTHORISED'].includes(actualInvoice.status) &&
                      <Col md={2}>
                        <FilterButtons
                          background={'#fff'}
                          disabled={loading}
                          onClick={() => !loading && sendEmail()}
                        >
                          {`Email`}
                        </FilterButtons>
                      </Col>
                    }
                  </Row>
                  <Row className={'mt-3'}>
                    <ClipLoader
                      css={override}
                      size={40}
                      color={`#7B61E4`}
                      loading={loading}
                    />
                    <Col lg={10}>
                      <table className={'w-100'}>
                        <thead>
                          <tr>
                            <th tabIndex="0">
                              Code
                            </th>
                            <th tabIndex="0">
                              Name
                            </th>
                            <th tabIndex="0">
                              Description
                            </th>
                            <th tabIndex="0">
                              Amount
                            </th>
                            <th tabIndex="0">
                              QTY
                            </th>
                            <th tabIndex="0">
                              TAX
                            </th>
                            <th tabIndex="0">
                              TOTAL
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {actualInvoice.line_items.map(item => {
                            return <tr>
                              <td>
                                {item.item_code}
                              </td>
                              <td>
                                {item.item_name}
                              </td>

                              <td>
                                {item.description}
                              </td>

                              <td>
                                {item.unit_amount}
                              </td>

                              <td>
                                {item.quantity}
                              </td>

                              <td>
                                {item.tax_amount}
                              </td>

                              <td>
                                {item.line_amount}
                              </td>
                            </tr>
                          })}

                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
                :

                <CardBody>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Payment Due</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {constants.formatDate(selectedPayment.scheduled_at)}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Amount to be charged</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {paymentEdit.amount}
                      </h3>
                    </Col>
                  </Row>
                </CardBody>
              }
              {actualInvoice === null ?
                <CardBody>
                  {selectedPayment && selectedPayment.reference && selectedPayment.reference.process_type === processTypeDirectDebit && <Row>
                    <Col sm={3}>
                      <small className="text-uppercase font-weight-bold">Payment Reference</small>
                    </Col>
                    <Col sm={9}>
                      <h3 className="heading mb-0">{selectedPayment.reference.payment_gateway_reference_id}</h3>
                    </Col>
                  </Row>}
                  {selectedPayment && selectedPayment.reference && selectedPayment.reference.payment_status === paymentStatusFailed && <Row>
                    <Col sm={12}>
                      <h3 className="text-danger heading mb-0">{`Payment failed! You can take future actions by retrying the payment!`}</h3>
                    </Col>
                  </Row>}
                  <InputCustom
                    type={`date`}
                    label={`Charge At`}
                    value={paymentEdit && paymentEdit.scheduled_at ? paymentEdit.scheduled_at : ''}
                    onChange={(e) => setPaymentEdit({
                      ...paymentEdit,
                      scheduled_at: e.target.value,
                    })}
                  />
                  <InputCustom
                    type={`number`}
                    label={`Amount`}
                    value={paymentEdit && paymentEdit.amount ? paymentEdit.amount : ''}
                    onChange={(e) => setPaymentEdit({
                      ...paymentEdit,
                      amount: e.target.value,
                    })}
                  />

                  <>
                    <InputArraySelect
                      label={`Module`}
                      options={modules.filter(item => item.module_type === 'accounting')}
                      value={paymentEdit && paymentEdit.selected_provider ? paymentEdit.selected_provider : ''}
                      valueKey={`provider`}
                      labelKey={`provider`}
                      onChange={(e) =>
                        setPaymentEdit({
                          ...paymentEdit,
                          selected_provider: e.target.value
                        })
                      }
                      defaultOption={true}
                      invalid={errors && errors['customer.company.business_activity_id'] ? true : false}
                      errorMessage={errors['customer.company.business_activity_id']}
                    />
                    <FormGroup>
                      <label className="form-control-label">{`Submit invoice now`}</label>
                      <div>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={paymentEdit.generate_now === 1 ? true : false}
                            onChange={() =>
                              setPaymentEdit({
                                ...paymentEdit,
                                generate_now: paymentEdit.generate_now === 1 ? 0 : 1
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </div>
                    </FormGroup>
                  </>

                  {selectedPayment.invoice && paymentEdit.process_type === 'invoice' && paymentOnlineProcessLink &&
                    <Row className="mt-2">
                      <Col md={12}>
                        <i
                          className="fas fa-credit-card"
                          style={{ cursor: 'pointer', marginLeft: "10px" }}
                          onClick={() => openPaymentLink(paymentOnlineProcessLink)}
                        > Go to online payment provider</i>
                      </Col>
                    </Row>
                  }
                  {!(selectedPayment.reference
                    && selectedPayment.reference.process_type === processTypeDirectDebit
                    && selectedPayment.reference.payment_status !== paymentStatusNew)
                    && <Button
                      className="new-event--add mt-2"
                      color="primary"
                      type="button"
                      onClick={() => savePayment()}
                    >
                      {`Save`}
                    </Button>}
                </CardBody> :
                (
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <p>We have invoice</p>
                      </Col>
                    </Row>
                  </CardBody>
                )
              }
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addInvoicePaymentModal}
          toggle={() => setAddInvoicePaymentModal(false)}
          className="modal-dialog-centered modal-md"
        >
          <div className="modal-header">
            <h2>{`Add payment`}</h2>
          </div>
          <div className="modal-body">
            {customPaymentElementError && <Row>
              <Col>
                <p className="alert alert-warning">{customPaymentElementError}</p>
              </Col>
            </Row>}
            <InputCustom
              type={`date`}
              label={`Date`}
              value={customPaymentElement && customPaymentElement.paid_on ? customPaymentElement.paid_on : ''}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                paid_on: e.target.value,
              })}
            />
            <InputCustom
              type={`number`}
              label={`Amount`}
              value={customPaymentElement && customPaymentElement.amount ? customPaymentElement.amount : ''}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                amount: e.target.value,
              })}
            />
            <InputArraySelect
              options={salesAccounts}
              value={customPaymentElement.sales_account ?? ''}
              valueKey={"code"}
              placeholder={`Sales Account`}
              label={"Sales Account"}
              labelKey={"name"}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                sales_account: e.target.value,
              })}
              defaultOption={true}
            />
            <InputCustom
              type={`text`}
              label={`Reference`}
              value={customPaymentElement && customPaymentElement.note ? customPaymentElement.note : ''}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                note: e.target.value,
              })}
            />
          </div>
          <div className="modal-footer mb-4">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addCustomInvoicePayment()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="danger"
              type="button"
              onClick={() => {
                setAddInvoicePaymentModal(false)
              }}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <AddNote isNoteModalVisible={addNoteModal} notify={notify} toggleModal={() => {
          setAddNoteModal(!addNoteModal);
        }} />
        <UpdateThemeBrandingModal
          actualInvoice={actualInvoice}
          brandingThemes={brandingThemes}
          isNoteModalVisible={updateThemeBrandingModal}
          notify={notify}
          toggleModal={() => {
            setUpdateThemeBrandingModal(!updateThemeBrandingModal);
          }} />
      </Container>
    </>
  )
}