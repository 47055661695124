import React, { useState, useEffect } from "react";
import { Button, Modal } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from '@redux/Deal/actions';
import InputCustom from './../../../components/InputCustom';
import { InputKeySelect, InputArraySelect } from 'views/pages/components/InputSelect';

export const UpdateThemeBrandingModal = (props) => {
    const dispatch = useDispatch();
    const dealState = useSelector((state) => state.dealState, shallowEqual);

    const {brandingThemes, actualInvoice} = props; 
    const { isNoteModalVisible, toggleModal, notify } = props;
    const [theme, setTheme] = useState(null);
    const [deal, setDeal] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);

    useEffect(() => {
        if (dealState.paymentDetails) {
            let data = dealState.paymentDetails;
            setDeal(data.deal);
            setSelectedPayment(data.item);
        }
    }, [dealState])

    useEffect(() => {
        if (actualInvoice && actualInvoice.theme_id) {
            setTheme(actualInvoice.theme_id);
        }
    }, [actualInvoice])

    useEffect(() => {
        if (dealState.updateThemeSuccessfully === true) {
            notify('success', 'Success', "Theme succesfully updated!");
            toggleModal();
        } else if (dealState.updateThemeSuccessfully === false) {
            notify('warning', 'Warning', "Error saving the Theme!");
        }
    }, [dealState.updateThemeSuccessfully]);

    const addNote = () => {
        dispatch({
            type: actions.UPDATE_INVOICE_THEME_REQUEST,
            payload: {
                theme,
                dealId: deal.id,
                paymentId: selectedPayment.id
            }
        });
    }

    return (
        actualInvoice && actualInvoice.theme_id ?
        (<Modal
            isOpen={isNoteModalVisible}
            toggle={() => toggleModal(false)}
            className="modal-dialog-centered modal-lg"
        >
            <div className="modal-header">
                <h2>{`Update theme branding`}</h2>
            </div>
            <div className="modal-body">
                <InputKeySelect
                    label={`Theme Type`}
                    value={theme ?? actualInvoice.theme_id}
                    options={brandingThemes}
                    onChange={(e) => {
                        setTheme(e.target.value)
                    }}
                    defaultOption={true}
                />
            </div>
            <div className="modal-footer mb-4">
                <Button
                    className="new-event--add"
                    color="primary"
                    type="button"
                    onClick={() => addNote()}
                >
                    {`Save`}
                </Button>
                <Button
                    className="ml-auto"
                    color="danger"
                    type="button"
                    onClick={() => {
                        toggleModal(false)
                    }}
                >
                    {`Close`}
                </Button>
            </div>
        </Modal>) : null
    );
}